import React, { useEffect } from "react";
import "../../../node_modules/swiper/swiper.min.css";
import striptags from "striptags";
import handleViewport from "react-in-viewport";
import {
  Navigation,
  Scrollbar,
  A11y,
  Controller,
  Thumbs,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

const WhyHire = (props) => {
  const { forwardedRef, inViewport } = props;
  const whyHireData = props.whyHireData;

  let slide = {
    modules: [Navigation, Controller, Scrollbar, A11y, Thumbs],
    loop: true,
    spaceBetween: 20,
    slidesPerView: 1.2,
    watchSlidesProgress: true,
    // centeredSlides: true,
    onSwiper: null,
    autoplay: {
      delay: 2500,
      disableOnInteraction: true,
    },
    speed: 2000,
    pagination: {
      clickable: true,
    },
    navigation: {
      nextEl: ".swiper-next",
      prevEl: ".swiper-prev",
    },
    breakpoints: {
      1920: {
        slidesPerView: 3,
      },
      991: {
        slidesPerView: 3,
      },
      767: {
        slidesPerView: 2,
      },
    },
  };

  useEffect(() => {
    console.clear();
  }, []);

  return (
    <section ref={forwardedRef} className="why-hire-section primary-background">
      {inViewport && (
        <div className="why-hire-inner">
          <div className="container">
            <div className="row">
              <div className="col-md-7">
                <div className="section-heading">
                  <span itemProp="heading" dangerouslySetInnerHTML={{
                    __html: whyHireData?.value?.title,
                  }}>
                  </span>
                  <p itemProp="description">
                    {striptags(whyHireData?.value?.description)}
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="project-types-slider">
                <div className="swiper swiper-project-types">
                  <Swiper {...slide}>
                    {whyHireData?.value?.list?.map((val, index) => {
                      return (
                        <SwiperSlide key={index}>
                          <div className="project-type-card">
                            <div className="project-type-number">
                              <span itemProp="number">0{index + 1}</span>
                            </div>
                            <div className="project-type-details">
                              <h3
                                itemProp="name"
                                dangerouslySetInnerHTML={{
                                  __html: val?.value?.title,
                                }}
                              ></h3>
                              <p
                                itemProp="description"
                                dangerouslySetInnerHTML={{
                                  __html: val?.value?.description,
                                }}
                              ></p>
                            </div>
                          </div>
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

const WhyHireViewportBlock = handleViewport(WhyHire);

export default WhyHireViewportBlock;
